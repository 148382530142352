var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_contact") } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-input-search", {
                        attrs: {
                          placeholder: _vm.$t("lbl_search_here"),
                          "data-testid": "contact-search",
                          "allow-clear": "",
                          "enter-button": ""
                        },
                        model: {
                          value: _vm.state.search,
                          callback: function($$v) {
                            _vm.$set(_vm.state, "search", $$v)
                          },
                          expression: "state.search"
                        }
                      }),
                      _c("a-button", {
                        attrs: {
                          icon: "reload",
                          type: "primary",
                          "data-testid": "contact-reset"
                        },
                        on: { click: _vm.reset }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 8, align: "center" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      attrs: { "button-style": "solid" },
                      on: { change: _vm.onChangeTab },
                      model: {
                        value: _vm.currentTab,
                        callback: function($$v) {
                          _vm.currentTab = $$v
                        },
                        expression: "currentTab"
                      }
                    },
                    [
                      _c(
                        "a-radio-button",
                        {
                          attrs: {
                            value: "customer",
                            "data-testid": "contact-type-customer"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_customer")) + " ")]
                      ),
                      _c(
                        "a-radio-button",
                        {
                          attrs: {
                            value: "supplier",
                            "data-testid": "contact-type-supplier"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_supplier")) + " ")]
                      ),
                      _c(
                        "a-radio-button",
                        {
                          attrs: {
                            value: "employee",
                            "data-testid": "contact-type-employee"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_employee")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { align: "end", span: 8 } },
                [
                  _c(
                    "a-space",
                    [
                      _vm.isCustomer && _vm.$can("sync", "contact-data")
                        ? [
                            _c(
                              "a-tooltip",
                              {
                                attrs: {
                                  title: _vm.lastSyncTitle,
                                  placement: "left"
                                }
                              },
                              [
                                _c("a-button", {
                                  attrs: {
                                    icon: "file-sync",
                                    "data-testid": "contact-sync",
                                    loading: _vm.loading.sync
                                  },
                                  on: { click: _vm.sync }
                                })
                              ],
                              1
                            )
                          ]
                        : _vm._e(),
                      _vm.$can("create", "contact-data")
                        ? _c(
                            "RouterLink",
                            {
                              attrs: {
                                to: { name: "settings.contact.create" },
                                "data-testid": "contact-create"
                              }
                            },
                            [
                              _c("a-button", { attrs: { type: "primary" } }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("lbl_create_new")) + " "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c("IndexContact", {
                    attrs: { type: _vm.currentTab, search: _vm.state.search },
                    on: { done: _vm.onFetchDone }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24, align: "end" } },
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.loading.download,
                        "data-testid": "contact-download"
                      },
                      on: { click: _vm.handleDownload }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }